import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const team = ({ block }) => {
  const { color, heading, text, teamList } = block

  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  let sectionColor 

  if (color === "white") {
    sectionColor = "bg-white text-black"
  }
  if (color === "grey") {
    sectionColor = "bg-[#f2f2f2] text-black"
  }
  if (color === "black") {
    sectionColor = "bg-black text-white"
  }
  if (color === null) {
    sectionColor = "bg-black text-white"
  }

  return (
    <motion.section className={"section  py-12 " + sectionColor} ref={ref}
    animate={controls}
    initial="hidden"
    variants={motionSection}>
      <div className="site-container">
        <div className="section__header mb-16 grid justify-center text-center">
          <div className="max-w-full lg:w-3/4 mx-auto">
            <motion.h2 className="h1 " variants={motionItems}>{heading}</motion.h2>
            <motion.p className="lead text-stone-500" variants={motionItems}>{text}</motion.p>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
          {teamList.map((item, index) => (

            <motion.div className="flex gap-4" key={item + index} variants={motionItems}>
              <GatsbyImage
                className="rounded-full aspect-square w-16 h-16"
                image={item.image.asset.gatsbyImageData}
                alt={item.image.asset.altText}
              />
              <div>
                <h3 className=" mb-1 font-bold uppercase">{item.name}</h3>
                <p className="uppercase text-sm">{item.position}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  )
}

export default team

const motionSection = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      staggerChildren: 0.15,
      staggerDirection: 1,
      delay: 0.3,
      ease: [0.38, 0.005, 0.215, 1],
      when: "beforeChildren",
    },
  },
}
const motionItems = {
  hidden: {
    opacity: 0,
    y: 20,
    // rotate: 20,
  },
  visible: {
    opacity: 1,
    // right: 0,
    y: 0,
    transition: {
      duration: 0.6,
      ease: [0.38, 0.005, 0.215, 1],
    },
  },
}