import React, { useEffect } from "react"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const motionSection = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      staggerChildren: 0.15,
      staggerDirection: 1,
      delay: 0.1,
      ease: [0.38, 0.005, 0.215, 1],
      when: "beforeChildren",
    },
  },
}
const motionItems = {
  hidden: {
    opacity: 0,
    y: 20,
    // rotate: 20,
  },
  visible: {
    opacity: 1,
    // right: 0,
    y: 0,
    transition: {
      duration: 0.6,
      ease: [0.38, 0.005, 0.215, 1],
    },
  },
}

const missionStatement = ({ block }) => {
  const { disabled, heading, reverseColumns, text, image, color } = block

  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  let sectionColor

  if (color === "white") {
    sectionColor = "bg-white text-black"
  }
  if (color === "grey") {
    sectionColor = "bg-[#f2f2f2] text-black"
  }
  if (color === "black") {
    sectionColor = "bg-black text-white"
  }
  return (
    <motion.section
      className={"section " + sectionColor}
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={motionSection}
    >
      <div className="site-container mx-auto">
        <div className="">
          <motion.span
            className="font-semibold tracking-wider uppercase mb-4 inline-block"
            variants={motionItems}
          >
            {heading}
          </motion.span>
          <motion.h2 className="h1" variants={motionItems}>
            {text}
          </motion.h2>
        </div>
      </div>
    </motion.section>
  )
}

export default missionStatement
