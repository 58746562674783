import React from "react"
import { RiArrowRightLine } from "react-icons/ri";

const jobListing = ({ block }) => {
  const { heading, text } = block

  return (
    <section className="section section-jobListing">
      <div className="site-container">
        <div className="section-richtext__content">
          <h1 className="h1 mb-3">{heading}</h1>
          <p className="lead mb-12">{text}</p>

          <div className="grid divide-y">
            <a href="#" className="py-6">
              <div className="inline-flex items-center bg-stone-700 text-stone-200 px-5 py-1.5 rounded-full mb-4">
                <span className="tracking-wider uppercase text-xs inline-block">Remote</span>
              </div>
              
              <div className="flex justify-between items-center">
                <h3 className="text-2xl">Software Engineer</h3>
                <RiArrowRightLine />
              </div>
            </a>
            <a href="#" className="py-6">
              <div className="inline-flex items-center bg-stone-700 text-stone-200 px-5 py-1.5 rounded-full mb-4">
                <span className="tracking-wider uppercase text-xs inline-block">Remote</span>
              </div>
              <div className="flex justify-between items-center">
                <h3 className="text-2xl">Data Analyst</h3>
                <RiArrowRightLine />
              </div>
            </a>
            <a href="#" className="py-6">
             <div className="inline-flex items-center bg-stone-700 text-stone-200 px-5 py-1.5 rounded-full mb-4">
                <span className="tracking-wider uppercase text-xs inline-block">Remote</span>
              </div>
              <div className="flex justify-between items-center">
                <h3 className="text-2xl">Digital Marketer</h3>
                <RiArrowRightLine />
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default jobListing
