import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"


const imageText = ({ block }) => {
  const { color, heading, text, image, multicolumnItem, reverseColumns } = block

  let reverse

  if (reverseColumns === true) {
    reverse = "lg:flex-row-reverse"
  }
  if (reverseColumns === false) {
    reverse = "lg:flex-row"
  }

  let sectionColor

  if (color === "white") {
    sectionColor = "bg-white text-black"
  }
  if (color === "grey") {
    sectionColor = "bg-[#f2f2f2] text-black"
  }
  if (color === "black") {
    sectionColor = "bg-black text-white"
  }
  if (color === null) {
    sectionColor = "bg-black text-white"
  }

  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <motion.section
      className={"section " + sectionColor}
    >
      <motion.div className={"site-container"} ref={ref}
      animate={controls}
      initial="hidden"
      variants={motionSection}>
        <div
          className={
            "flex flex-col lg:flex-row items-center gap-8 text-center lg:text-center " +
            reverse
          }
        >
          <div className="w-full lg:w-6/12">
            <motion.h1 className="h1 mb-3" variants={motionItems}>
              {block.heading}
            </motion.h1>

            {image && (
              <motion.div
                variants={motionItems}
                className="flex lg:hidden order-1 lg:order-2 w-full w-full lg:w-6/12 overflow-hidden mt-4 mb-4"
              >
                <GatsbyImage
                  className="aspect-square rounded-lg"
                  image={image.asset.gatsbyImageData}
                  alt={image.asset.altText}
                />
              </motion.div>
            )}

            <motion.p className="lead mb-12 text-stone-500" variants={motionItems}>
              {block.text}
            </motion.p>
            <ul className="flex flex-col gap-6">
              {multicolumnItem.map((item, index) => (
                <motion.li variants={motionItems}>
                  <h3 className="h3">{item.heading}</h3>
                  <p>{item.description}</p>
                </motion.li>
              ))}
            </ul>
          </div>

          {image && (
            <motion.div
              variants={motionItems}
              className="hidden lg:flex order-1 lg:order-2 w-full w-full lg:w-6/12 overflow-hidden"
            >
              <GatsbyImage
                className="aspect-square rounded-lg"
                image={image.asset.gatsbyImageData}
                alt={image.asset.altText}
              />
            </motion.div>
          )}
        </div>
      </motion.div>
    </motion.section>
  )
}

export default imageText

const motionSection = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      staggerChildren: 0.15,
      staggerDirection: 1,
      delay: 0.3,
      ease: [0.38, 0.005, 0.215, 1],
      when: "beforeChildren",
    },
  },
}
const motionItems = {
  hidden: {
    opacity: 0,
    y: 20,
    // rotate: 20,
  },
  visible: {
    opacity: 1,
    // right: 0,
    y: 0,
    transition: {
      duration:.6,
      ease: [0.38, 0.005, 0.215, 1],
    },
  },
}

const animImg = {
  hidden: {
    scale: 1.2
  },
  visible: {
    scale: 1,
    transition: {
      duration: 1.5,
      ease: [0.38, 0.005, 0.215, 1],
    },
  },
}