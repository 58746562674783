import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const feature = ({ block }) => {
  const { color, label, heading, text, featureList, subheading } = block

  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  console.log("feature", block)

  let sectionColor

  if (color === "white") {
    sectionColor = "bg-white text-black"
  }
  if (color === "grey") {
    sectionColor = "bg-[#f2f2f2] text-black"
  }
  if (color === "black") {
    sectionColor = "bg-black text-white"
  }
  return (
    <motion.section
      className={"section " + sectionColor}
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={motionSection}
    >
      <div className="site-container grid grid-cols-1 lg:grid-cols-5 gap-8">
        <div className="col-span-2">
        <p className="subheading">{subheading}</p>
          
          <motion.h2 className="h1 mb-1" variants={motionItems}>
            {heading}
          </motion.h2>
          <motion.p className="lead" variants={motionItems}>
            {text}
          </motion.p>
        </div>
        <motion.div className="col-span-3 grid grid-cols-1 lg:grid-cols-2 divide-y lg:divide-none lg:gap-8" variants={motionItems}>
          {featureList.map((feature, index) => (
            <div className="py-4 lg:py-0" key={feature + index}>
              
              <div className="grid gap-4">
                <span >{label}</span>
                <div className="flex justify-between">
                <h3 className="h3" >
                  {feature.heading}
                </h3>
                {feature.image && (
                <div >
                  <GatsbyImage
                    image={feature.image.asset.gatsbyImageData}
                    alt={feature.image.asset.altText}
                  />
                </div>
              )}
                </div>
                
                <p className="">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </motion.div>
      </div>
    </motion.section>
  )
}

export default feature

const motionSection = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      staggerChildren: 0.15,
      staggerDirection: 1,
      delay: 0.15,
      ease: [0.38, 0.005, 0.215, 1],
      when: "beforeChildren",
    },
  },
}
const motionItems = {
  hidden: {
    opacity: 0,
    y: 20,
    // rotate: 20,
  },
  visible: {
    opacity: 1,
    // right: 0,
    y: 0,
    transition: {
      duration: 0.6,
      ease: [0.38, 0.005, 0.215, 1],
    },
  },
}
