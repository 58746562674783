import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const motionSection = {
  hidden: {
    opacity: 0,
    // y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      staggerChildren: 0.05,
      staggerDirection: 1,
      // delay: 0.15,
      ease: [0.38, 0.005, 0.215, 1],
      when: "beforeChildren",
    },
  },
}
const motionItems = {
  hidden: {
    opacity: 0,
    y: 20,
    // rotate: 20,
  },
  visible: {
    opacity: 1,
    // right: 0,
    y: 0,
    transition: {
      duration: 0.6,
      ease: [0.38, 0.005, 0.215, 1],
    },
  },
}
const animImg = {
  hidden: {
    scale: 1.2
  },
  visible: {
    scale: 1,
    transition: {
      duration: 1.5,
      ease: [0.38, 0.005, 0.215, 1],
    },
  },
}

const hero = ({ block }) => {
  const { label, heading, text, image, file, height, heroItem} = block
  
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  let heroHeight;

  if (height == "100") {
    heroHeight = "h-screen"
  } else if ( height == "90") {
    heroHeight = "h-[90vh]"
  } else if ( height == "80") {
    heroHeight = "h-[80vh]"
  } else if ( height == "70") {
    heroHeight = "h-[70vh]"
  } else if ( height == "60") {
    heroHeight = "h-[60vh]"
  } else if ( height == "50") {
    heroHeight = "h-[50vh]"
  }

  return (
    <motion.section className="overflow-hidden" ref={ref}
    animate={controls}
    initial="hidden"
    variants={motionSection}>
      <div className="relative after:content-[''] after:absolute after:inset-0 after:bg-black/50">
        <div className="absolute inset-0 flex items-center justify-start z-10">
          <div className="site-container text-neutral-50 px-6 ">
            <div className="w-[650px] max-w-full grid gap-4">
              {label && (
                <motion.span className="text-sm tracking-wider font-semibold uppercase" variants={motionItems}>
                  {label}
                </motion.span>
              )}
              
              {heading && (
                <motion.h1 className="display font-bold " variants={motionItems}>{heading}</motion.h1>
              )}
              
              {text && (
                <motion.p className="display-lead" variants={motionItems}>{text}</motion.p>
              )}
              
              {heroItem.length > 0 && (
                <div className="grid grid-cols-1 mb-6 divide-y divide-stone-500">
                  {heroItem.map((item, index) => (
                    <motion.div className="py-4 text-lg font-semibold " variants={motionItems}>
                      {item.text}
                    </motion.div>
                  ))}
                </div>
              )}
              
            </div>
          </div>
        </div>
        <div className="">
          {image && (
            <motion.div variants={animImg} className="h-full w-full">
              <GatsbyImage
                className={heroHeight + ""} 
                image={image.asset.gatsbyImageData}
                alt={image.asset.altText}
              />    
            </motion.div>
          )}

          {file && (
            <motion.video
              variants={animImg}
              className={"w-full object-cover " + heroHeight}
              autoPlay={true}
              muted={true}
              loop={true}
              controls={false}
              playsInline
            >
              <source src={file.asset.url} type="video/mp4" />
            </motion.video>
          )}
        </div>
      </div>
    </motion.section>
  )
}

export default hero
