import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { IoTrendingUpSharp } from "react-icons/io5";

const motionSection = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      staggerChildren: 0.15,
      staggerDirection: 1,
      delay: 0.3,
      ease: [0.38, 0.005, 0.215, 1],
      when: "beforeChildren",
    },
  },
}
const motionItems = {
  hidden: {
    opacity: 0,
    y: 20,
    // rotate: 20,
  },
  visible: {
    opacity: 1,
    // right: 0,
    y: 0,
    transition: {
      duration: 0.6,
      ease: [0.38, 0.005, 0.215, 1],
    },
  },
}

const stats = ({ block }) => {
  const { color, heading, text, statsList, subheading } = block

  const controls = useAnimation()
  const [ref, inView] = useInView({
    threshold: .1,
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  let sectionColor

  if (color === "white") {
    sectionColor = "bg-white text-black"
  }
  if (color === "grey") {
    sectionColor = "bg-[#f2f2f2] text-black"
  }
  if (color === "black") {
    sectionColor = "bg-black text-white"
  }

  return (
    <motion.section
      className={"section threeGrid py-12 " + sectionColor}
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={motionSection}
    >
      <div className="site-container">

        {heading && (
          <div className="section__header mb-16 grid text-center">
            <div className="max-w-screen-sm mx-auto">
              <p className="subheading">{subheading}</p>
              <h2 className="text-3xl font-semibold mb-2">{heading}</h2>
              <p className="lead text-stone-500">{text}</p>
            </div>
          </div>
        )}
        

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {statsList.map((item, index) => (
            <motion.article className="relative px-6 pt-12 pb-6 bg-neutral-900 border border-neutral-800 rounded-lg shadow-sm" variants={motionItems}>
              <p className="text-lg font-medium text-neutral-500">{item.stat}</p>
            
              <p className="inline-flex items-end mt-1">
                <span className="text-6xl font-medium leading-none text-white">{item.result}</span>
                <span className="ml-1 text-xs text-stone-500">{item.compare}</span>
              </p>
            
              <p className="absolute inline-flex p-1 text-green-600 bg-green-100 rounded top-4 right-4">
                <IoTrendingUpSharp />
            
                <span className="ml-1.5 text-xs font-medium">
                  {item.percent}
                </span>
              </p>
            </motion.article>

            // <motion.div
            //   className="p-4"
            //   key={item + index}
            //   variants={motionItems}
            // >
            //   <div className="grid">
            //   <motion.span
            //     className="font-semibold tracking-wider uppercase mb-4 inline-block color-accent"
            //     variants={motionItems}
            //   >
            //     0{index + 1}
            //   </motion.span>
            //     <h3 className="h1 pb-2">{item.stat}</h3>
            //     <p className="lead color-muted">{item.label}</p>
            //   </div>
            // </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  )
}

export default stats
