import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const motionSection = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      staggerChildren: 0.15,
      staggerDirection: 1,
      delay: 0.3,
      ease: [0.38, 0.005, 0.215, 1],
      when: "beforeChildren",
    },
  },
}
const motionItems = {
  hidden: {
    opacity: 0,
    y: 20,
    // rotate: 20,
  },
  visible: {
    opacity: 1,
    // right: 0,
    y: 0,
    transition: {
      duration: 0.6,
      ease: [0.38, 0.005, 0.215, 1],
    },
  },
}

const multicolumn = ({ block }) => {
  const { color, heading, text, multicolumnItem, galleryItem, subheading } = block

  const controls = useAnimation()
  const [ref, inView] = useInView({
    threshold: .1,
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  let sectionColor

  if (color === "white") {
    sectionColor = "bg-white text-black"
  }
  if (color === "grey") {
    sectionColor = "bg-[#f2f2f2] text-black"
  }
  if (color === "black") {
    sectionColor = "bg-black text-white"
  }

  return (
    <motion.section
      className={"section multicolumn py-12 " + sectionColor}
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={motionSection}
    >
      <div className="site-container">
        <div className="section__header mb-16 grid justify-center text-center">
          <div className="">
            <p className="subheading">{subheading}</p>
            <h2 className="h1 ">{heading}</h2>
            <p className="lead">{text}</p>
          </div>

          {/* <div className="flex gap-3">
            {galleryItem.map((item, index) => (
              <div className="" key={item + index}>
                <GatsbyImage
                  className="rounded-full aspect-square overflow-hidden h-[100px] w-[100px]"
                  image={item.image.asset.gatsbyImageData}
                  alt={item.image.asset.altText}
                  layout="contrained"
                  height={100}
                  width={100}
                  aspectRatio={1}
                />
              </div>
            ))}
          </div> */}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {multicolumnItem.map((item, index) => (
            // <div className="outline outline-1 outline-black p-6" key={item + index}>

            <motion.div
              className=""
              key={item + index}
              variants={motionItems}
            >
              {/* <div className="shadow-xl shadow-neutral-300/50  rounded-md p-6 border border-neutral-100 bg-white" key={item + index}> */}
              {/* <GatsbyImage 
                image={item.image.asset.gatsbyImageData}
                alt={item.image.asset.altText}
              /> */}
              <div className="grid divide-y-4 divide-[#51afea]">
                <h3 className="h2 pb-4">{item.heading}</h3>
                <p className="lead pt-4">{item.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  )
}

export default multicolumn
