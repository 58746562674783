import React from 'react'
import feature from '../modules/feature';
import hero from '../modules/hero';
import imageText from '../modules/imageText';
import logoCloud from '../modules/logoCloud';
import multicolumn from '../modules/multicolumn';
import richText from '../modules/richText';
import imageMulticolumnOverlay from '../modules/imageMulticolumnOverlay';
import team from '../modules/team';
import latestNews from '../modules/latestNews';
import contactForm from '../modules/contactForm';
import missionStatement from '../modules/missionStatement';
import threeGrid from '../modules/threeGrid';
import jobListing from '../modules/jobListing';
import stats from '../modules/stats';

const PageBuilder = (props) => {
  const { type, pageBuilder, _rawPageBuilder } = props;
  const Components = {
    hero: hero,
    feature: feature,
    imageText: imageText,
    logoCloud: logoCloud,
    multicolumn: multicolumn,
    richText: richText,
    imageMulticolumnOverlay: imageMulticolumnOverlay,
    team: team,
    latestNews: latestNews,
    contactForm: contactForm,
    missionStatement: missionStatement,
    threeGrid: threeGrid,
    jobListing: jobListing,
    stats: stats,
  }
  return pageBuilder.map((block, index) => {
    if (Components[block._type]) {
      return React.createElement(Components[block._type], {
        key: block._key,
        block: block,
        type,
        raw: _rawPageBuilder[index]
      })
    }
  })
}

export default PageBuilder
