import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const motionSection = {
  hidden: {
    opacity: 0,
    // y: 20,
  },
  visible: {
    opacity: 1,
    // y: 0,
    transition: {
      duration: 0.3,
      staggerChildren: 0.05,
      staggerDirection: 1,
      // delay: 0.15,
      ease: [0.38, 0.005, 0.215, 1],
      when: "beforeChildren",
    },
  },
}
const motionItems = {
  hidden: {
    opacity: 0,
    y: 20,
    // rotate: 20,
  },
  visible: {
    opacity: 1,
    // right: 0,
    y: 0,
    transition: {
      duration: 0.6,
      ease: [0.38, 0.005, 0.215, 1],
    },
  },
}
const animImg = {
  hidden: {
    scale: 1.2
  },
  visible: {
    scale: 1,
    transition: {
      duration: 1.5,
      ease: [0.38, 0.005, 0.215, 1],
    },
  },
}

const multicolumn = ({ block }) => {
  const { heading, text, multicolumnItem, image } = block

  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <motion.section className="overflow-hidden" ref={ref}
    animate={controls}
    initial="hidden"
    variants={motionSection}>
      <div
        className="module-imagemulticolumnoverlay__content lg:h-screen"
      >
        <motion.div variants={animImg}>
          <GatsbyImage
            className="w-full after:content-[''] after:bg-black/50 after:absolute after:inset-0"
            image={image.asset.gatsbyImageData}
            alt={image.asset.altText}
          />
        </motion.div>

        <div className="lg:absolute lg:inset-0 lg:flex lg:items-center lg:justify-center">
          <div className="grid z-10 text-white py-12">
            <div className="site-container">
              <div className="section__header mb-16 grid justify-center text-center">
                <div className="max-w-screen-md mx-auto">
                  <motion.h2 className="display font-semibold pb-2" variants={motionItems}>{heading}</motion.h2>
                  <motion.p className="lead max-w-screen-sm mx-auto text-stone-400" variants={motionItems}>{text}</motion.p>
                </div>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-3 text-center gap-8" >
                {multicolumnItem.map((item, index) => (
                  <div className="" key={item + index}>
                    <div>
                      <motion.h3 className="h2 mb-4" variants={motionItems}>{item.heading}</motion.h3>
                      <motion.p className="lead text-neutral-400" variants={motionItems}>{item.description}</motion.p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </motion.section>
  )
}

export default multicolumn
