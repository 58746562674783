import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const motionSection = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      staggerChildren: 0.15,
      staggerDirection: 1,
      delay: 0.3,
      ease: [0.38, 0.005, 0.215, 1],
      when: "beforeChildren",
    },
  },
}
const motionItems = {
  hidden: {
    opacity: 0,
    y: 20,
    // rotate: 20,
  },
  visible: {
    opacity: 1,
    // right: 0,
    y: 0,
    transition: {
      duration: 0.6,
      ease: [0.38, 0.005, 0.215, 1],
    },
  },
}

const threeGrid = ({ block }) => {
  const { color, heading, text, threeGridItem, subheading } = block

  const controls = useAnimation()
  const [ref, inView] = useInView({
    threshold: .1,
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  let sectionColor

  if (color === "white") {
    sectionColor = "bg-white text-black"
  }
  if (color === "grey") {
    sectionColor = "bg-[#f2f2f2] text-black"
  }
  if (color === "black") {
    sectionColor = "bg-black text-white"
  }

  return (
    <motion.section
      className={"section threeGrid py-12 " + sectionColor}
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={motionSection}
    >
      <div className="site-container">

        {heading && (
          <div className="section__header mb-16 grid">
            <div className="max-w-screen-sm">
              <p className="subheading">{subheading}</p>
              <h2 className="text-2xl text-stone-500">{heading}</h2>
              <p className="lead ">{text}</p>
            </div>
          </div>
        )}
        

        <div className="grid grid-cols-1 lg:grid-cols-3 lg:divide-x lg:divide-white">
          {threeGridItem.map((item, index) => (
            // <div className="outline outline-1 outline-black p-6" key={item + index}>

            <motion.div
              className="p-4"
              key={item + index}
              variants={motionItems}
            >
              {/* <div className="shadow-xl shadow-neutral-300/50  rounded-md p-6 border border-neutral-100 bg-white" key={item + index}> */}
              {/* <GatsbyImage 
                image={item.image.asset.gatsbyImageData}
                alt={item.image.asset.altText}
              /> */}
              <div className="grid">
              <motion.span
                className="font-semibold tracking-wider uppercase mb-4 inline-block color-accent"
                variants={motionItems}
              >
                0{index + 1}
              </motion.span>
                <h3 className="h1 pb-2">{item.heading}</h3>
                <p className="lead text-stone-500">{item.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  )
}

export default threeGrid
