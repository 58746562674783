import React from "react"
import "./richText.scss";
import { PortableText } from "@portabletext/react"

const richText = ({ block }) => {
  const { _rawPortableText } = block

  return (
    <section className="section section-richtext">
      <div className="site-container">
        <div className="section-richtext__content">
          <div className="prose max-w-full prose-invert">
            <PortableText
              value={_rawPortableText}
              components={{
                types: {
                  image: ({ value }) => (
                    <GatsbyImage
                      image={getGatsbyImageData(
                        value.asset._ref,
                        { maxWidth: 1024 },
                        sanityConfig
                      )}
                    />
                  ),
                },
              }}
              onMissingComponent={false}
            />
          </div>
          
        </div>
      </div>
    </section>
  )
}

export default richText
