import React from 'react'
import { graphql } from "gatsby";
import PageBuilder from '../components/pageBuilder';
import Layout from '../components/layout';

const PageTemplate = (props) => {
  const {data} = props
  const page = data && data.page
  const {pageBuilder, _rawPageBuilder} = page

  console.log("page template", props)
  return (
    <Layout>
      <PageBuilder pageBuilder={pageBuilder} _rawPageBuilder={_rawPageBuilder} />
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: {eq: $id}) {
      id
      title
      slug {
        current
      }
      ...PageBuilder
    }
  }
`