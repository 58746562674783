import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const logoCloud = ({ block }) => {
  const { heading, text, logoList } = block
  return (
    <section className="section">
      <div>
        <h2>{heading}</h2>
        <p>{text}</p>
      </div>

      <div className="flex">
        {logoList.map((logo, index) => (
          <div>
            <h3>{logo.title}</h3>
            <GatsbyImage
              image={logo.image.asset.gatsbyImageData}
              alt={logo.image.asset.altText}
            />
          </div>
        ))}
      </div>
    </section>
  )
}

export default logoCloud
