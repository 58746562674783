import React, { useEffect, useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const multicolumn = ({ block }) => {
  const { heading, contactFormItem } = block

  //form handling
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    reason: "",
    message: "",
  })

  const handleChange = e => {
    const { name, value } = e.target
    // setFormData({ ...formData, [name]: value })
    let finalValue = e.target.type === "checkbox" ? e.target.checked : value

    console.log("finalValue", finalValue)


    setFormData({ ...formData, [name]: finalValue })
  }

  console.log("formData", formData)

  //form validations
  const [errors, setErrors] = useState({})

  const validate = formData => {
    let formErrors = {}
    if (!formData.fullName) {
      formErrors.fullName = "First name required"
    }
    if (!formData.email) {
      formErrors.email = "Email required"
    }
    if (!formData.reason) {
      formErrors.reason = "Reason required"
    }

    if (!formData.message) {
      formErrors.message = "Message is required"
    }
    return formErrors
  }

  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleSubmit = e => {
    setErrors(validate(formData))
    setIsSubmitted(true)
    e.preventDefault()
  }

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitted) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact-form", ...formData }),
      })
        .then(() => alert("Success!"))
        .then(() => setIsSubmitted(false))
        .then(() =>
          setFormData({ fullName: "", email: "", reason:"", message: "" })
        )
        .catch(error => alert(error))
    }
  }, [errors, formData, isSubmitted])
  console.log(errors, formData)

  return (
    <section className={"section multicolumn py-12 bg-white text-black"}>
      <div className="site-container">
        <div className="section__header mb-16 grid justify-center text-center">
          <h2 className="h1 ">{heading}</h2>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">

          <div className="col-span-1 grid gap-8 order-2 lg:order-1">
            <div className="contact-form__list grid gap-8">
              {contactFormItem.map((item, index) => (
                <div className="" key={item + index}>
                  <div>
                    <h3 className="h2 mb-2 font-bold">{item.heading}</h3>
                    <p>{item.address1}</p>
                    <p>{item.address2}</p>
                    <p>{item.emailAddress}</p>
                    <p>{item.phone}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="contact-form__socials">
              <h3 className="font-bold">Follow our mission</h3>
              <ul>
                <li>LinkedIn</li>
                <li>Twitter</li>
                <li>Github</li>
              </ul>
            </div>
          </div>


          <div className="col-span-2 order-1 lg:order-2">
            <form 
              name="contact-form"
              method="post"  
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact-form" />
              
              <label className="">
                <span className="text-gray-700">Full name</span>
                <input
                  type="text"
                  placeholder="John Appleseed"
                  className="form-input 
                    mt-1
                    block
                    w-full
                    rounded-md
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                    mb-6
                  "
                  id="fullName"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
              </label>

              <label>
                <span className="text-gray-700">Email addresss</span>
                <input
                  type="email"
                  placeholder="john@example.com"
                  className="form-input 
                    mt-1
                    block
                    w-full
                    rounded-md
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                    mb-6
                  "
                  value={formData.email}
                  onChange={handleChange}
                  id="email"
                  name="email"
                  required
                />
              </label>

              <div className="contact-form__checkboxes grid mb-6">
                <label className="">
                <span>Reason for contact</span>
                <select
                  type="select"
                  className="
                    block
                    w-full
                    mt-1
                    rounded-md
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  "
                  id="reason"
                  name="reason"
                  value={formData.reason}
                  onChange={handleChange}
                  required
                >
                  <option value=""></option>
                  <option value="Investment Inquiries">Investment Inquiries</option>
                  <option value="Join our team">Join our team</option>
                  <option value="General questions">General questions</option>
                </select>
              </label>
                {/* <fieldset>
                  <legend><h3 className="text-gray-700">Reason</h3></legend>

                  <label className="flex gap-2">
                  <input
                    type="checkbox"
                    className="form-input 
                      mt-1
                      rounded-md
                      border-gray-300
                      shadow-sm
                      focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    id="reason"
                    name="reason"
                    checked={formData.reason.investmentInquiries}
                    onChange={handleChange}
                  />
                  <span className="text-gray-700">Investment Inquiries</span>
                </label>
                <label className="flex gap-2">
                  <input
                    type="checkbox"
                    className="form-input 
                    mt-1
                    rounded-md
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                    "
                    id="reasonJoinOurTeam"
                    name="reasonJoinOurTeam"
                    checked={formData.reason.joinOurTeam}
                    onChange={handleChange}
                  />
                  <span className="text-gray-700">Join our team</span>
                </label>
                <label className="flex gap-2">
                  <input
                    type="checkbox"
                    className="form-input 
                    mt-1
                    rounded-md
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  "
                    id="reasonGeneralQuestions"
                    name="reasonGeneralQuestions"
                    checked={formData.reason.generalQuestions}
                    onChange={handleChange}
                  />
                  <span className="text-gray-700">General questions</span>
                </label>
                </fieldset>
                */}
              </div>

              <label className="">
                <span className="text-gray-700">Message</span>
                <textarea
                  className="form-input 
                    mt-1
                    block
                    w-full
                    rounded-md
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                    mb-6
                  "
                  rows="6"
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </label>

              <input
                type="submit"
                value="Submit message"
                className="btn-secondary"
              />

            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default multicolumn
