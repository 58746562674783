import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import useNewsData from "../hooks/useNewsData"
import { Link } from "gatsby"

const latestNews = ({ block }) => {
  const { heading } = block
  const { news } = useNewsData()

  console.log("useNewsData", news)
  return (
    <section className="section">
      <div className="site-container mx-auto grid  gap-16 items-center ">

          <h1 className="h1 mb-3">{block.heading}</h1>


        <div className="latest-news__list grid grid-cols-1 lg:grid-cols-3 gap-8">
          {news.map((post, index) => (
            <article key={post.slug + "-" + index} >
              <Link to={"/news/" + post.slug.current}>
                <GatsbyImage
                  className="aspect-video"
                  image={post.featuredImage.asset.gatsbyImageData}
                  alt={post.featuredImage.asset.altText}
                />
                <p>{post._createdAt}</p>
                <h2>{post.title}</h2>
              </Link>
            </article>
          ))}
        </div>
      </div>
    </section>
  )
}

export default latestNews
